<template>
  <div class="phoneRetrieval">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">账号找回</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="bindTitle">账号找回</div>
      <div class="WelcomeMessage">涩漫天堂 二次元的“性”世界</div>
      <!-- 手机号 -->
      <div class="inputBox">
        <div class="areaCodeBox">
          <router-link tag="div" to="/countryCode" class="areaCode">{{
            areaCode
          }}</router-link>
          <svg-icon class="areaCodeArrow" iconClass="navArrow" />
        </div>
        <van-field
          class="inputItem"
          v-model="phoneVal"
          placeholder="请输入手机号"
          @blur="changBlur"
        >
          <template #button v-if="phoneVal">
            <div @click="clearPhone">
              <svg-icon class="inputClose" iconClass="inputClose" />
            </div>
          </template>
        </van-field>
      </div>
      <!-- 验证码 -->
      <div class="inputBox mt44" style="border-bottom: none;">
        <van-field
          class="inputItem"
          label="验证码"
          :maxlength="8"
          v-model="msgVal"
          placeholder="请输入验证码"
          @blur="changBlur"
        >
          <template #button>
            <div class="loading" v-if="loading">
              <van-loading type="spinner" color="#ec8737" size="20" />
            </div>
            <div class="msgCodeBtn" v-else-if="timer">{{ timer }}s后重发</div>
            <div class="msgCodeBtn" v-else @click="sendCode">获取验证码</div>
          </template>
        </van-field>
      </div>

      <div class="nextBtn" @click="login">确定输入</div>

      <!-- <div class="codeHelpBox" @click="showCodeHelp = true">
        <div>收不到验证码？</div>
      </div> -->
    </div>
    <!-- 验证码帮助 -->
    <van-popup v-model="showCodeHelp" class="popup">
      <div class="popupBox">
        <div class="popTitle">收不到验证码？</div>
        <div class="codeHelpContent">
          1.请检查是否输入正确的手机号码<br />
          2.请检查手机是否停机<br />
          3.请使用其他账号绑定<br />
          4.请联系官方客服
        </div>
        <div class="popBtn">
          <div @click="showCodeHelp = false">我知道了</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Field, Loading, Toast, Popup } from "vant";
import { setSessionItem, setLocalItem } from "@/utils/longStorage";
import { sendCode, phoneLogin } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  components: {
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Loading.name]: Loading,
  },
  computed: {
    ...mapGetters({
      areaCode: "areaCode",
    }),
  },
  data() {
    return {
      phoneVal: "", // 手机号
      msgVal: "", // 验证码
      showCodeHelp: false, // 是否显示验证码帮助
      loading: false, // loading
      timer: 0, // 倒计时时间
    };
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 清除手机号
    clearPhone() {
      this.phoneVal = "";
    },
    // 倒计时
    countdown() {
      this.timer = 60;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    // 发送验证码
    async sendCode() {
      if (!this.phoneVal) {
        Toast("请输入手机号");
        return;
      }
      this.loading = true;
      let req = {
        mobile: this.areaCode + this.phoneVal,
        type: 2, // 1-绑定手机号  2-手机号登录
      };
      /******** 测试代码 **********/
      // setTimeout(() => {
      //     this.loading = false;
      //     this.countdown();
      // }, 3000)
      /******** 测试代码 **********/
      let ret = await this.$Api(sendCode, req);
      this.loading = false;
      if (ret && ret.code == 200) {
        Toast("验证短信已发出 请注意查收");
        this.countdown();
      } else {
        // console.log(ret.tip || "验证码发送失败");
        Toast(ret.tip || "验证码发送失败");
      }
    },
    // 登录
    async login() {
      if (!this.phoneVal) {
        Toast("请输入手机号");
        return;
      }
      if (!this.msgVal) {
        Toast("请输入验证码");
        return;
      }
      let req = {
        code: this.msgVal,
        mobile: this.areaCode + this.phoneVal,
      };
      let ret = await this.$Api(phoneLogin, req);
      if (ret && ret.code == 200) {
        setSessionItem("userInfo", JSON.stringify(ret.data));
        setLocalItem("token", ret.data.token);
        this.$store.dispatch("commit/SET_USERINFO", ret.data);
        this.$router.go(-3);
        Toast("登录成功");
      } else {
        // console.log(ret.tip || "验证码发送失败");
        Toast(ret.tip || "登录失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phoneRetrieval {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;

    .bindTitle {
      color: #000;
      font-size: 30px;
      margin: 44px 0 0 32px;
    }

    .WelcomeMessage {
      margin: 10px 0 65px 32px;
      font-size: 16px;
      color: #666;
    }

    .inputBox {
      display: flex;
      align-items: center;
      width: 292px;
      margin: 0 auto;
      font-size: 14px;
      padding-bottom: 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #e6e6e6;

      /deep/ .van-field__label {
        width: 44px;
        margin-right: 27px;
        color: #000;
      }

      .areaCodeBox {
        display: flex;
        align-items: center;
        margin-right: 27px;
        color: #000;

        .areaCodeArrow {
          width: 12px;
          height: 12px;
          transform: rotate(-90deg);
          margin-bottom: 4px;
          margin-left: 4px;
        }
      }

      .inputItem {
        padding: 0;
        background: #00000000;

        /deep/ .van-field__control {
          font-size: 14px;
          color: #000;
        }

        ::placeholder {
          color: #999999;
        }

        .inputClose {
          width: 17px;
          height: 17px;
        }

        .msgCodeBtn {
          color: #fc4162;
          font-size: 13px;
        }
      }
    }

    .confirmTipBox {
      font-size: 12px;
      margin: 16px 42px 29px;
      display: flex;
      align-items: center;
      color: #626079;

      .checkIcon {
        margin-right: 10px;
      }

      /deep/ .van-checkbox__label {
        color: #626079;
      }

      /deep/ .van-checkbox__icon {
        display: flex;
        align-items: center;
      }

      .unselected {
        width: 10px;
        height: 10px;
        border: 1px solid #626079;
        border-radius: 50%;
      }

      .selectedIcon {
        width: 12px;
        height: 12px;
      }
    }

    .nextBtn {
      width: 284px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      border-radius: 44px;
      color: #424242;
      background: #fbd13d;
      margin: 63px auto 0;
      text-align: center;
    }

    .isDisableBtn {
      background: #abaaba;
    }

    .codeHelpBox {
      width: 292px;
      font-size: 13px;
      color: #999999;
      margin: 19px auto 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .popup {
    background: #00000000;

    .popupBox {
      width: 304px;
      border-radius: 9px;
      overflow: hidden;
      background: #1b164c;

      .popTitle {
        font-size: 18px;
        text-align: center;
        margin: 18px 0;
      }

      .popContent {
        font-size: 15px;
        padding: 0 18px;
        line-height: 26px;
        box-sizing: border-box;
      }

      .codeHelpContent {
        font-size: 15px;
        line-height: 26px;
        padding: 0 42px;
        color: #b1b1b1;
        box-sizing: border-box;
      }

      .popHelpContent {
        font-size: 15px;
        line-height: 26px;
        text-align: center;
      }

      .popBtn {
        width: 100%;
        height: 56px;
        font-size: 18px;
        margin-top: 24px;
        border-top: 1px solid #322e61;
        color: #27f8d9;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // width: 304px;
  }

  .mt44 {
    margin-top: 44px !important;
  }
}
</style>
